import React from "react";
import Header from "../dashboard/Header";

function TAx() {
  // Sample data for the table
  const data = [
    {
      month: "Edgardo Padlan",
      absent: "1800.00",
      present: "200.00",
      workingDays: "200.00",
    },
    {
      month: "Kylan Joyce",
      absent: "1800.00",
      present: "200.00",
      workingDays: "200.00",
    },
    {
      month: "July",
      absent: "1800.00",
      present: "200.00",
      workingDays: "200.00",
    },
    {
      month: "Patryk Donnelly",
      absent: "1800.00",
      present: "200.00",
      workingDays: "200.00",
    },
    {
      month: "Kylan Joyce",
      absent: "1800.00",
      present: "200.00",
      workingDays: "200.00",
    },
    {
      month: "Edgardo Padlan",
      absent: "1800.00",
      present: "200.00",
      workingDays: "200.00",
    },
  ];

  return (
    <>
      <Header />
      <div className="p-6 bg-[#CBE4FD] rounded-lg shadow-lg">
        <h2 className="text-xl font-semibold mb-4">Teacher Attendance</h2>
        <table className="min-w-full bg-white rounded-md shadow-md">
          <thead>
            <tr className="bg-blue-600 text-white">
              <th className="py-2 px-4 border-b">Teacher Name</th>
              <th className="py-2 px-4 border-b">PF</th>
              <th className="py-2 px-4 border-b">P TAX</th>
              <th className="py-2 px-4 border-b">Total</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr
                key={index}
                className={`hover:bg-blue-100 ${
                  index % 2 === 0 ? "bg-white" : "bg-gray-50"
                }`}
              >
                <td className="py-2 px-4 border-b text-center">{row.month}</td>
                <td className="py-2 px-4 border-b text-center">{row.absent}</td>
                <td className="py-2 px-4 border-b text-center">
                  {row.present}
                </td>
                <td className="py-2 px-4 border-b text-center">
                  {row.workingDays}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default TAx;
