import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  FaUserPlus,
  FaTags,
  FaEdit,
  FaClipboardCheck,
  FaCertificate,
  FaUsers,
  FaBook,
  FaMoneyBillAlt,
} from "react-icons/fa";
import Header from "./Header";
import logoLeft from "../img/1000077337-removebg-preview.png";
import logoRight from "../img/cbse-logo-46D5A6B556-seeklogo.com.png";

function Dashboard() {
  const navigate = useNavigate();

  // Navigation options with paths and corresponding icons
  const sections = [
    {
      name: "Add Student",
      path: "/add-student",
      icon: <FaUserPlus size={60} />,
    },
    {
      name: "Fee Category",
      path: "/category-for-fee",
      icon: <FaTags size={60} />,
    },
    { name: "Update Fees", path: "/studentF", icon: <FaEdit size={60} /> },
    {
      name: "Attendance ",
      path: "/attendance",
      icon: <FaClipboardCheck size={60} />,
    },
    {
      name: "Certificate",
      path: "/studentTable",
      icon: <FaCertificate size={60} />,
    },
    { name: "Staff", path: "/staffHome", icon: <FaUsers size={60} /> },
    { name: "Library", path: "/bookpage", icon: <FaBook size={60} /> },
    { name: "Expense", path: "/expense", icon: <FaMoneyBillAlt size={60} /> },
    {
      name: "PF & PTax Report",
      path: "/tax",
      icon: <FaClipboardCheck size={60} />,
    },
  ];

  // Define an array of gradient colors
  const gradients = [
    "bg-gradient-to-r from-blue-500 to-purple-500",
    "bg-gradient-to-r from-green-500 to-teal-500",
    "bg-gradient-to-r from-red-500 to-orange-500",
    "bg-gradient-to-r from-yellow-500 to-pink-500",
    "bg-gradient-to-r from-indigo-500 to-blue-400",
    "bg-gradient-to-r from-gray-500 to-gray-400",
    "bg-gradient-to-r from-pink-500 to-purple-600",
    "bg-gradient-to-r from-teal-600 to-lime-500",
    "bg-gradient-to-r from-blue-500 to-purple-500",
  ];

  // Function to handle navigation on click
  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <div>
      <div className="flex flex-col sm:flex-row py-4 items-center justify-center relative z-10">
        <div className="w-32 h-32 sm:w-52 sm:h-52">
          <img
            src={logoLeft}
            alt="School Logo"
            loading="lazy"
            title="School Logo"
            className="object-contain h-full w-full"
          />
        </div>
        <div className="text-center mb-6 sm:mb-12">
          <h2 className="text-red-700 mb-2 font-bold text-2xl sm:text-3xl">
            INDURA ENGLISH SCHOOL (CBSE)
          </h2>
          <p className="font-semibold">
            Enjangaon (East), Tq, Basmath Dist Hingoli
          </p>
          <p className="font-semibold">
            UDISE No.: 27160301903 Affiliation No.: 1131230 School Code: 31217
          </p>
          <div className="font-semibold">
            <a
              href="http://www.induraenglishschool.in"
              className="block sm:inline mx-4"
            >
              Website: www.induraenglishschool.in
            </a>
            <a
              href="mailto:induraenglishschool@gmail.com"
              className="block sm:inline mx-4"
            >
              Email: induraenglishschool@gmail.com
            </a>
          </div>
        </div>
        <div className="w-32 h-32 sm:w-40 sm:h-40 mb-4 sm:mb-6">
          <img
            src={logoRight}
            alt="CBSE Logo"
            loading="lazy"
            title="CBSE Logo"
            className="object-contain h-full w-full"
          />
        </div>
      </div>
      <Box className="p-4 font-serif bg-gray-100 min-h-screen">
        <Grid container spacing={2} justifyContent="center">
          {sections.map((section, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box
                className={`h-[200px] w-full ${gradients[index]} p-6 shadow-lg rounded-lg transition-all duration-300 cursor-pointer flex flex-col items-center justify-between border-2 border-transparent hover:border-white hover:scale-105`}
                onClick={() => handleNavigate(section.path)}
              >
                <Typography variant="h5" className="text-white text-center  ">
                  {section.name}
                </Typography>
                <Box className="text-white">{section.icon}</Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
}

export default Dashboard;
