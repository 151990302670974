import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const FeeTableComponent = () => {
  const { id } = useParams();
  const [feeData, setFeeData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [ismodalOpen, setisModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currentFee, setCurrentFee] = useState({});
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [editData, setEditData] = useState({
    student_id: id,
    amount_paid: "",
    discount_amount: "",
    discount_reason: "",
    payment: "",
  });

  const [formData, setFormData] = useState({
    fee_category: "",
    fee_subcategory: "",
    payment: "",
    fee_amount: "",
    amount_paid: "",
    discount_amount: "",
    discount_reason: "",
    student_id: id,
  });

  // Fetch fee data on component mount
  useEffect(() => {
    axios
      .get(`https://api.indura.hemsidaavt.com/fee/${id}`)
      .then((response) => {
        const filteredData = response.data.filter((fee) => fee.fee_id !== 0);
        setFeeData(filteredData);
      })
      .catch((error) => {
        console.error("There was an error fetching the fee data!", error);
      });
  }, [id]);

  // Fetch fee categories when modal opens
  const fetchCategories = () => {
    axios
      .get("https://api.indura.hemsidaavt.com/api/fee-categories")
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {
        console.error("Error fetching categories", error);
      });
  };

  // Fetch subcategories when a category is selected
  const fetchSubcategories = (categoryId) => {
    axios
      .get(
        `https://api.indura.hemsidaavt.com/api/fee-subcategories?categoryId=${categoryId}`
      )
      .then((response) => {
        setSubcategories(response.data);
      })
      .catch((error) => {
        console.error("Error fetching subcategories", error);
      });
  };

  // Open modal and fetch categories for new or existing payment
  const handleModalOpen = (fee = null) => {
    fetchCategories(); // Fetch categories when the modal opens
    setModalOpen(true);
    if (fee) {
      setIsEdit(true);
      setCurrentFee(fee);
      setFormData({
        fee_category: fee.fee_category,
        fee_subcategory: fee.fee_subcategory,
        payment: fee.payment,
        fee_amount: fee.fee_amount,
        amount_paid: fee.amount_paid,
        discount_amount: fee.discount_amount || "",
        discount_reason: fee.discount_reason || "",
        student_id: id,
      });
    } else {
      setIsEdit(false);
      setFormData({
        fee_category: "",
        fee_subcategory: "",
        payment: "",
        fee_amount: "",
        amount_paid: "",
        discount_amount: "",
        discount_reason: "",
        student_id: id,
      });
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setCurrentFee({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Fetch subcategories when category changes
    if (name === "fee_category") {
      fetchSubcategories(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const endpoint = isEdit
      ? `https://api.indura.hemsidaavt.com/api/payment/${currentFee.fee_id}`
      : "https://api.indura.hemsidaavt.com/api/payment";

    const method = isEdit ? "put" : "post";

    axios[method](endpoint, formData)
      .then((response) => {
        window.location.reload();
        handleModalClose();
      })
      .catch((error) => {
        console.error(
          isEdit ? "Error updating fee data" : "Error adding payment",
          error
        );
      });
  };
  const handleEdit = async (e) => {
    e.preventDefault();
    console.log(editData);
    try {
      // `https://api.indura.hemsidaavt.com/api/payment/${currentFee.fee_id} put

      await axios
        .put(
          `https://api.indura.hemsidaavt.com/api/payment/${currentFee.fee_id}`,
          editData
        )
        .then((response) => {
          window.location.reload();
          setEditData({
            student_id: id,
            amount_paid: "",
            discount_amount: "",
            discount_reason: "",
            payment: "",
          });
          handleModalClose();
        })
        .catch((error) => {
          console.error("Error updating fee data", error);
        });
    } catch (err) {
      console.error("Error updating fee data".err);
    }
  };

  const groupByFeeCategory = (data) => {
    const groupedData = {};
    data.forEach((fee) => {
      if (!groupedData[fee.fee_category]) {
        groupedData[fee.fee_category] = [];
      }
      groupedData[fee.fee_category].push(fee);
    });
    return groupedData;
  };

  const groupedFeeData = groupByFeeCategory(feeData);
  console.log(feeData);
  const studentInfo = feeData[0];
  const handleView = async (id) => {
    await axios
      .get(`https://api.indura.hemsidaavt.com/feeHistory/${id}`)
      .then((response) => {
        setPaymentDetails(response.data);
        setisModalOpen(true);
      })
      .catch((err) => {
        console.error("Error fetching fee history", err);
      });
  };
  const handleisModalClose = () => {
    setisModalOpen(false);
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-semibold mb-4">Fee Details</h2>


      <div className="mb-4">
        <input
          type="text"
          // value={searchQuery}
          // onChange={handleSearchChange}
          placeholder="Search by ID, Class, Mobile No, or Email ID"
          className="w-full p-2 border rounded"
        />
      </div>
      {/* Student Info Section */}
      {feeData.length > 0 && (
        <div className="mb-4">
          <h3 className="text-xl font-bold">Student Information</h3>
          <p>
            <strong>Name:</strong> {studentInfo?.student_name}
          </p>
          <p>
            <strong>Class:</strong> {studentInfo?.student_class}
          </p>
          <p>
            <strong>Division:</strong> {studentInfo?.student_division}
          </p>
        </div>
      )}

      <button
        className="bg-blue-500 text-white py-2 px-4 rounded mb-6"
        onClick={() => handleModalOpen()}
      >
        Add Payment
      </button>

      {/* Fee Categories and Payments */}
      {feeData.length > 0 &&
        Object.keys(groupedFeeData).map((feeCategory, idx) => (
          <div key={idx} className="mb-6">
            <h3 className="text-xl font-bold mb-2">{feeCategory} Fees</h3>
            <table className="min-w-full bg-white border border-gray-200 shadow-lg">
              <thead>
                <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                  <th className="py-3 px-6 text-left">Subcategory</th>
                  <th className="py-3 px-6 text-left">Fee Amount</th>
                  <th className="py-3 px-6 text-left">Amount Paid</th>
                  <th className="py-3 px-6 text-left">Discount Amount</th>
                  <th className="py-3 px-6 text-left">Discount Reason</th>
                  <th className="py-3 px-6 text-left">Carry forward fee</th>
                  <th className="py-3 px-6 text-left">Amount Due</th>
                  <th className="py-3 px-6 text-left">Actions</th>
                  <th className="py-3 px-6 text-left">View </th>
                </tr>
              </thead>
              <tbody className="text-gray-600 text-sm">
                {groupedFeeData[feeCategory].map((fee, index) => {
                  return (
                    <tr
                      key={index}
                      className="border-b border-gray-200 hover:bg-gray-100"
                    >
                      <td className="py-3 px-6 text-left">
                        {fee.fee_subcategory}
                      </td>

                      <td className="py-3 px-6 text-left">₹{fee.fee_amount}</td>
                      <td className="py-3 px-6 text-left">
                        ₹{fee.amount_paid}
                      </td>
                      <td className="py-3 px-6 text-left">
                        ₹{fee.discount_amount || "N/A"}
                      </td>
                      <td className="py-3 px-6 text-left">
                        {fee.discount_reason || "carry forward feeN/A"}
                      </td>
                      <td className="py-3 px-6 text-left">0.00</td>
                      <td className="py-3 px-6 text-left">
                        ₹{fee.amount_due_after_discount}
                      </td>

                      <td className="py-3 px-6 text-left">
                        <button
                          className="bg-yellow-500 text-white py-1 px-3 rounded"
                          onClick={() => handleModalOpen(fee)}
                        >
                          ADD
                        </button>
                      </td>
                      <td className="py-3 px-6 text-left">
                        <button
                          className="bg-blue-500 text-white py-1 px-3 rounded"
                          onClick={() => handleView(fee.fee_id)}
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ))}

      {/* Payment Modal */}
      {modalOpen && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg">
            <h3 className="text-lg font-semibold mb-4">
              {isEdit ? "Add Payment" : "Add Payment"}
            </h3>
            <form onSubmit={isEdit ? handleEdit : handleSubmit}>
              {!isEdit && (
                <>
                  <div className="mb-4">
                    <label className="block text-sm font-medium mb-2">
                      Category
                    </label>
                    <select
                      name="fee_category"
                      value={formData.fee_category}
                      onChange={handleInputChange}
                      className="w-full p-2 border border-gray-300 rounded"
                    >
                      <option value="">Select Category</option>
                      {categories.map((category) => (
                        <option
                          key={category.category_id}
                          value={category.category_id}
                        >
                          {category.category_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium mb-2">
                      Subcategory
                    </label>
                    <select
                      name="fee_subcategory"
                      value={formData.fee_subcategory}
                      onChange={handleInputChange}
                      className="w-full p-2 border border-gray-300 rounded"
                    >
                      <option value="">Select Subcategory</option>
                      {subcategories.map((subcategory) => (
                        <option
                          key={subcategory.subcategory_id}
                          value={subcategory.subcategory_id}
                        >
                          {subcategory.subcategory_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </>
              )}
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">
                  Mode of Payment
                </label>
                <input
                  type="text"
                  name="payment"
                  value={isEdit ? editData.payment : formData.payment}
                  onChange={
                    isEdit
                      ? (e) =>
                          setEditData({
                            ...editData,
                            payment: e.target.value,
                          })
                      : handleInputChange
                  }
                  className="w-full p-2 border border-gray-300 rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">
                  Fee Amount
                </label>
                <input
                  type="number"
                  name="fee_amount"
                  value={formData.fee_amount}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded"
                  disabled={isEdit}
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">
                  Amount Paid
                </label>
                <input
                  type="number"
                  name="amount_paid"
                  value={isEdit ? editData.amount_paid : formData.amount_paid}
                  onChange={
                    isEdit
                      ? (e) =>
                          setEditData({
                            ...editData,
                            amount_paid: e.target.value,
                          })
                      : handleInputChange
                  }
                  className="w-full p-2 border border-gray-300 rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">
                  Discount Amount
                </label>
                <input
                  type="number"
                  name="discount_amount"
                  value={
                    isEdit ? editData.discount_amount : formData.discount_amount
                  }
                  onChange={
                    isEdit
                      ? (e) => {
                          setEditData({
                            ...editData,
                            discount_amount: e.target.value,
                          });
                        }
                      : handleInputChange
                  }
                  className="w-full p-2 border border-gray-300 rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">
                  UTR Number
                </label>
                <input
                  type="text"
                  name="UTR Number"
                  className="w-full p-2 border border-gray-300 rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">
                  Discount Reason
                </label>
                <input
                  type="text"
                  name="discount_reason"
                  value={
                    isEdit ? editData.discount_reason : formData.discount_reason
                  }
                  onChange={
                    isEdit
                      ? (e) => {
                          setEditData({
                            ...editData,
                            discount_reason: e.target.value,
                          });
                        }
                      : handleInputChange
                  }
                  className="w-full p-2 border border-gray-300 rounded"
                />
              </div>
              <button
                type="submit"
                className="bg-blue-500 text-white py-2 px-4 rounded mr-2"
              >
                {isEdit ? "Update" : "Add"}
              </button>
              <button
                type="button"
                onClick={handleModalClose}
                className="bg-gray-500 text-white py-2 px-4 rounded"
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      )}
      {ismodalOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-xl w-full max-w-4xl">
            <h3 className="text-2xl font-semibold mb-6 border-b pb-2 border-gray-300">
              Payment Details
            </h3>
            {paymentDetails.length > 0 ? (
              <table className="min-w-full bg-white border border-gray-200 rounded-lg overflow-hidden">
                <thead>
                  <tr className="bg-gray-200 text-gray-700 uppercase text-sm leading-normal">
                    <th className="py-3 px-6 text-left">Payment ID</th>
                    <th className="py-3 px-6 text-left">Amount</th>
                    <th className="py-3 px-6 text-left">Date</th>
                    <th className="py-3 px-6 text-left">Method</th>
                  </tr>
                </thead>
                <tbody className="text-gray-600 text-sm">
                  {paymentDetails.map((payment, index) => (
                    <tr
                      key={payment.payment_id}
                      className="border-b border-gray-200 hover:bg-gray-100"
                    >
                      <td className="py-3 px-6 text-left">{index + 1}</td>
                      <td className="py-3 px-6 text-left">
                        ₹{payment.payment_amount}
                      </td>
                      <td className="py-3 px-6 text-left">
                        {payment.payment_date}
                      </td>
                      <td className="py-3 px-6 text-left">
                        {payment.payment_method}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p className="text-gray-600 mt-4">
                No payment details available.
              </p>
            )}
            <button
              type="button"
              onClick={handleisModalClose}
              className="bg-blue-600 text-white py-2 px-4 rounded mt-6 hover:bg-blue-700 transition duration-150 ease-in-out"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FeeTableComponent;
