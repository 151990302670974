import React, { useState } from "react";
import Header from "./Header";

const TEacher = () => {
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [attendanceData, setAttendanceData] = useState([
    { id: 1, studentName: "John Doe", status: "Present" },
    { id: 2, studentName: "Jane Smith", status: "Absent" },
    { id: 3, studentName: "Michael Johnson", status: "Present" },
  ]);

  const handleStatusToggle = (studentId) => {
    setAttendanceData((prevData) =>
      prevData.map((student) =>
        student.id === studentId
          ? {
              ...student,
              status: student.status === "Present" ? "Absent" : "Present",
            }
          : student
      )
    );
  };

  const filteredData = attendanceData.filter((student) =>
    student.studentName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <Header />
      <div className="p-8 bg-gray-50 min-h-screen">
        <h1 className="text-2xl font-bold text-center mb-8 text-indigo-700">
          Teacher Attendance
        </h1>

        {/* Search and All Present Button */}
        <div className="flex justify-between items-center mb-6">
          {/* Search Input Field */}
          <div className="w-3/5">
            <input
              type="text"
              className="block w-full py-2 px-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Search by student name..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          {/* Mark All Present Button */}
          <button
            className="py-2 px-6 bg-indigo-600 text-white font-semibold rounded-md shadow-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50"
            onClick={() =>
              setAttendanceData((prev) =>
                prev.map((student) => ({ ...student, status: "Present" }))
              )
            }
          >
            Mark All Present
          </button>
        </div>

        {/* Attendance Table */}
        <div className="overflow-x-auto shadow-md rounded-lg bg-white">
          <table className="min-w-full border-collapse">
            <thead>
              <tr className="bg-indigo-100 text-indigo-700">
                <th className="py-3 px-6 text-left font-semibold border">
                  Student ID
                </th>
                <th className="py-3 px-6 text-left font-semibold border">
                  Student Name
                </th>
                <th className="py-3 px-6 text-left font-semibold border">
                  Attendance Status
                </th>
                <th className="py-3 px-6 text-left font-semibold border">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredData.length > 0 ? (
                filteredData.map((row) => (
                  <tr key={row.id} className="hover:bg-gray-50">
                    <td className="py-4 px-6 border">{row.id}</td>
                    <td className="py-4 px-6 border">{row.studentName}</td>
                    <td className="py-4 px-6 border">{row.status}</td>
                    <td className="py-4 px-6 border">
                      <button
                        className={`py-1 px-4 rounded-md text-white font-semibold ${
                          row.status === "Present"
                            ? "bg-red-500 hover:bg-red-400"
                            : "bg-green-500 hover:bg-green-400"
                        }`}
                        onClick={() => handleStatusToggle(row.id)}
                      >
                        {row.status === "Present"
                          ? "Mark Absent"
                          : "Mark Present"}
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="text-center py-8 text-gray-500">
                    No students found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Attendance Summary */}
        <div className="mt-8 flex justify-between items-center">
          <div className="text-lg shadow-md p-32 font-semibold text-gray-700">
            Total Teacher: <span className="text-indigo-600">100</span>
          </div>
          <div className="text-lg  shadow-md p-32  font-semibold text-gray-700">
            Present Teacher: <span className="text-indigo-600">10</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default TEacher;
