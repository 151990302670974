import React from "react";
import { CgProfile } from "react-icons/cg";
import { CiSearch } from "react-icons/ci";
import { Link } from "react-router-dom";
import { IoRefresh } from "react-icons/io5";
import Header from "./Header";
function StaffHome() {
  return (
    <>
      <Header />
      <div className="p-6 space-y-8">
        {/* Search Input Field */}
        <div className="flex items-center space-x-2 w-full max-w-full">
          <input
            type="text"
            className="flex-grow py-2 px-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            placeholder="Search "
          />
          <button className="text-2xl text-white rounxl bg-[#355A80] px-4 p-1 hover:text-blue-700">
            <CiSearch />
          </button>
        </div>

        {/* Profile Header */}
        <div className="flex justify-between items-center bg-[#CBE4FD] p-4 rounded-lg shadow">
          <div className="flex items-center space-x-4">
            <CgProfile className="text-6xl text-gray-500" />
            <div>
              <p className="text-xl font-semibold">Teacher Name </p>
              <p className="text-sm text-gray-600">
                Manage your attendance and salary
              </p>
            </div>
          </div>
        </div>

        {/* Attendance Section */}
        <div>
          <div className=" flex justify-between items-center px-3">
            <h1 className="text-2xl font-semibold mb-2">Attendance</h1>
            {/* <p><IoRefresh /></p> */}
            <Link className="bg-[#CBE4FD] px-4 p-2" to="/his">
              <p>
                <IoRefresh />
              </p>
            </Link>
          </div>
          <div className="grid grid-cols-3 gap-4">
            <div className="bg-[#CBE4FD] p-12 rounded-lg shadow text-center">
              <p className="text-lg font-semibold">
                <span>20</span> Present
              </p>
            </div>
            <div className="bg-[#CBE4FD] p-12 rounded-lg shadow text-center">
              <p className="text-lg font-semibold">
                <span>5</span> Absent
              </p>
            </div>
            <div className="bg-[#CBE4FD] p-12 rounded-lg shadow text-center">
              <p className="text-lg font-semibold">
                <span>25</span> Working Days
              </p>
            </div>
          </div>
        </div>

        {/* Teacher Performance Section */}
        <div>
          <h1 className="text-2xl font-semibold mb-2">Teacher Performance</h1>
          <div className="bg-[#CBE4FD] p-4 rounded-lg shadow text-center">
            <p className="text-lg font-semibold">Average Attendance</p>
            <p className="text-2xl font-bold">95%</p>
          </div>
        </div>

        {/* Salary Information Section */}
        <div>
          <h1 className="text-2xl font-semibold mb-2">Salary Information</h1>
          <div className="bg-[#CBE4FD] justify-between flex p-4 rounded-lg shadow text-center space-y-2">
            <div>
              <p className="text-lg font-semibold">Total Salary</p>
              <p className="text-2xl font-bold">5000</p>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Select Month:
              </label>
              <select className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-[#355A80] text-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                <option>January</option>
                <option>February</option>
                <option>March</option>
              </select>
            </div>
          </div>
        </div>

        {/* Download Salary Slip Button */}
        <div className="text-center">
          <button className="mt-4 px-6 py-2 bg-[#355A80] text-white font-semibold rounded-md shadow ">
            Download Salary Slip
          </button>
        </div>
      </div>
    </>
  );
}

export default StaffHome;
