// import React, { useEffect, useState } from "react";
// import axios from "axios";

// const FeeSubCategoryComponent = () => {
//   const [subcategories, setSubCategories] = useState([]);
//   const [categories, setCategories] = useState([]);
//   const [subcategoryName, setSubCategoryName] = useState("");
//   const [categoryId, setCategoryId] = useState("");
//   const [editSubCategoryId, setEditSubCategoryId] = useState(null); // To track editing

//   // Fetch subcategories
//   const fetchSubCategories = async () => {
//     try {
//       const response = await axios.get(
//         "https://api.indura.hemsidaavt.com/api/fee-subcategories"
//       );
//       setSubCategories(response.data);
//     } catch (error) {
//       console.error("Error fetching subcategories", error);
//     }
//   };

//   // Fetch categories
//   const fetchCategories = async () => {
//     try {
//       const response = await axios.get(
//         "https://api.indura.hemsidaavt.com/api/fee-categories"
//       );
//       setCategories(response.data);
//     } catch (error) {
//       console.error("Error fetching categories", error);
//     }
//   };

//   // Create subcategory
//   const createSubCategory = async () => {
//     try {
//       const response = await axios.post(
//         "https://api.indura.hemsidaavt.com/api/fee-subcategories",
//         {
//           subcategory_name: subcategoryName,
//           category_id: categoryId,
//         }
//       );
//       setSubCategories([...subcategories, response.data]);
//       window.location.reload();
//       setSubCategoryName("");
//       setCategoryId("");
//     } catch (error) {
//       console.error("Error creating subcategory", error);
//     }
//   };

//   // Update subcategory
//   const updateSubCategory = async (subcategoryId) => {
//     try {
//       const response = await axios.put(
//         `https://api.indura.hemsidaavt.com/api/fee-subcategories/${subcategoryId}`,
//         {
//           subcategory_name: subcategoryName,
//           category_id: categoryId,
//         }
//       );
//       setSubCategories(
//         subcategories.map((subcategory) =>
//           subcategory.subcategory_id === subcategoryId
//             ? response.data
//             : subcategory
//         )
//       );
//       setEditSubCategoryId(null); // Clear editing state
//       setSubCategoryName(""); // Clear input field
//       setCategoryId("");
//     } catch (error) {
//       console.error("Error updating subcategory", error);
//     }
//   };

//   const handleFormSubmit = () => {
//     if (editSubCategoryId) {
//       updateSubCategory(editSubCategoryId);
//     } else {
//       createSubCategory();
//     }
//   };

//   // Start editing a subcategory
//   const handleEditClick = (subcategory) => {
//     setSubCategoryName(subcategory.subcategory_name);
//     setCategoryId(subcategory.category_id);
//     setEditSubCategoryId(subcategory.subcategory_id); // Set the ID for editing
//   };

//   useEffect(() => {
//     fetchSubCategories();
//     fetchCategories();
//   }, []);

//   return (
//     <div className="container">
//       <h2 className="title">Fee Sub Categories</h2>

//       <div className="input-section">
//         <input
//           className="input"
//           type="text"
//           value={subcategoryName}
//           onChange={(e) => setSubCategoryName(e.target.value)}
//           placeholder="Enter subcategory name"
//         />
//         <select
//           className="input"
//           value={categoryId}
//           onChange={(e) => setCategoryId(e.target.value)}
//         >
//           <option value="">Select Category</option>
//           {categories.map((category) => (
//             <option key={category.category_id} value={category.category_id}>
//               {category.category_name}
//             </option>
//           ))}
//         </select>

//         <button className="btn btn-create" onClick={handleFormSubmit}>
//           {editSubCategoryId ? "Update Subcategory" : "Add Subcategory"}
//         </button>
//       </div>

//       <ul className="category-list">
//         {subcategories.map((subcategory) => (
//           <li className="category-item" key={subcategory.subcategory_id}>
//             <span className="subcategory-name">
//               {subcategory.subcategory_name}
//             </span>
//             <button
//               className="btn bg-blue-200 m-4"
//               onClick={() => handleEditClick(subcategory)}
//             >
//               Edit
//             </button>
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// export default FeeSubCategoryComponent;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaRegEdit } from "react-icons/fa";

const FeeSubCategoryComponent = () => {
  const [subcategories, setSubCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategoryName, setSubCategoryName] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [editSubCategoryId, setEditSubCategoryId] = useState(null);

  // Fetch subcategories
  const fetchSubCategories = async () => {
    try {
      const response = await axios.get(
        "https://api.indura.hemsidaavt.com/api/fee-subcategories"
      );
      setSubCategories(response.data);
    } catch (error) {
      console.error("Error fetching subcategories", error);
    }
  };

  // Fetch categories
  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        "https://api.indura.hemsidaavt.com/api/fee-categories"
      );
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories", error);
    }
  };

  // Create subcategory
  const createSubCategory = async () => {
    try {
      const response = await axios.post(
        "https://api.indura.hemsidaavt.com/api/fee-subcategories",
        {
          subcategory_name: subcategoryName,
          category_id: categoryId,
        }
      );
      setSubCategories([...subcategories, response.data]);
      window.location.reload();
      setSubCategoryName("");
      setCategoryId("");
    } catch (error) {
      console.error("Error creating subcategory", error);
    }
  };

  // Update subcategory
  const updateSubCategory = async (subcategoryId) => {
    try {
      const response = await axios.put(
        `https://api.indura.hemsidaavt.com/api/fee-subcategories/${subcategoryId}`,
        {
          subcategory_name: subcategoryName,
          category_id: categoryId,
        }
      );
      setSubCategories(
        subcategories.map((subcategory) =>
          subcategory.subcategory_id === subcategoryId
            ? response.data
            : subcategory
        )
      );
      setEditSubCategoryId(null); // Clear editing state
      setSubCategoryName(""); // Clear input field
      setCategoryId("");
    } catch (error) {
      console.error("Error updating subcategory", error);
    }
  };

  const handleFormSubmit = () => {
    if (editSubCategoryId) {
      updateSubCategory(editSubCategoryId);
    } else {
      createSubCategory();
    }
  };

  // Start editing a subcategory
  const handleEditClick = (subcategory) => {
    setSubCategoryName(subcategory.subcategory_name);
    setCategoryId(subcategory.category_id);
    setEditSubCategoryId(subcategory.subcategory_id); // Set the ID for editing
  };

  useEffect(() => {
    fetchSubCategories();
    fetchCategories();
  }, []);

  return (
    <div className="container mx-auto p-6 ">
      {/* Title Section */}
      <div className="flex justify-center mb-6">
        <div className="bg-[#00307D] text-white p-4 rounded-lg shadow-lg text-center w-full sm:w-2/3">
          <h2 className="text-2xl font-semibold">Fee Sub Categories</h2>
        </div>
      </div>

      {/* Input Section */}
      <div className="flex flex-col md:flex-row justify-center items-center gap-4 mb-8">
        <input
          className="input border border-gray-300 p-3 rounded-md w-full md:w-1/3"
          type="text"
          value={subcategoryName}
          onChange={(e) => setSubCategoryName(e.target.value)}
          placeholder="Enter subcategory name"
        />
        <select
          className="input border border-gray-300 p-3 rounded-md w-full md:w-1/3"
          value={categoryId}
          onChange={(e) => setCategoryId(e.target.value)}
        >
          <option value="">Select Category</option>
          {categories.map((category) => (
            <option key={category.category_id} value={category.category_id}>
              {category.category_name}
            </option>
          ))}
        </select>
        <button
          className="btn bg-[#00307D] text-white px-5 py-3 rounded-md shadow hover:bg-[#002b6d] transition-all"
          onClick={handleFormSubmit}
        >
          {editSubCategoryId ? "Update Subcategory" : "Add Subcategory"}
        </button>
      </div>

      {/* List of Subcategories */}
      <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {subcategories.map((subcategory) => (
          <li key={subcategory.subcategory_id} className="p-4">
            <div className="bg-white p-4 rounded-lg shadow-lg flex justify-between items-center">
              <span className="block text-lg font-medium">
                {subcategory.subcategory_name}
              </span>
              <button
                className="text-[#2B75EB] p-2 hover:text-[#1f5fb5] transition-all"
                onClick={() => handleEditClick(subcategory)}
              >
                <FaRegEdit size={20} />
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FeeSubCategoryComponent;
