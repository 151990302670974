import React, { useState, useEffect } from "react";
import Header from "./Header";

const Attendance = () => {
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [attendanceData, setAttendanceData] = useState([]);
  const [classes, setClasses] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Fetch classes from the API
  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const response = await fetch("http://localhost:8800/api/class/classes");
        const data = await response.json();
        console.log(data); // assuming the response is the array of classes
        setClasses(data); // assuming the response is the array of classes
      } catch (error) {
        console.error("Error fetching classes:", error);
      }
    };
    fetchClasses();
  }, []);

  // Fetch divisions when the class is selected
  useEffect(() => {
    const fetchDivisions = async () => {
      if (selectedClass) {
        try {
          const response = await fetch(
            `http://localhost:8800/api/class/divisions/${selectedClass}`
          );
          const data = await response.json();
          console.log(data);
          setDivisions(data); // assuming the response is the array of divisions
        } catch (error) {
          console.error("Error fetching divisions:", error);
        }
      }
    };
    fetchDivisions();
  }, [selectedClass]);

  // Fetch students when both class and division are selected
  useEffect(() => {
    const fetchStudents = async () => {
      if (selectedClass && selectedSection) {
        setIsLoading(true);
        try {
          const response = await fetch(
            `http://localhost:8800/api/class/students/${selectedClass}/${selectedSection}`
          );
          const data = await response.json();
          console.log(data); // assuming the response is the array of students
          setAttendanceData(data); // assuming the response is the array of students
        } catch (error) {
          console.error("Error fetching students:", error);
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchStudents();
  }, [selectedClass, selectedSection]);

  // Toggle attendance status
  const handleStatusToggle = (studentId) => {
    setAttendanceData((prevData) =>
      prevData.map((student) =>
        student.id === studentId
          ? {
              ...student,
              status: student.status === "Present" ? "Absent" : "Present",
            }
          : student
      )
    );
  };

  // Filtered data based on search term
  const filteredData = attendanceData.filter((student) =>
    student.studentName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <Header />
      <div className="p-8 bg-gray-50 min-h-screen">
        <h1 className="text-2xl font-bold text-center mb-8 text-indigo-700">
          Students Attendance
        </h1>

        {/* Class and Section Dropdown */}
        <div className="flex justify-center space-x-8 mb-6">
          {/* Class Dropdown */}
          <div className="w-48">
            <label className="block text-sm font-semibold text-gray-700">
              Class
            </label>
            <select
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={selectedClass}
              onChange={(e) => setSelectedClass(e.target.value)}
            >
              <option value="">Select Class</option>
              {classes.map((cls) => (
                <option key={cls.id} value={cls.id}>
                  {cls.class_name}
                </option>
              ))}
            </select>
          </div>

          {/* Section (Division) Dropdown */}
          <div className="w-48">
            <label className="block text-sm font-semibold text-gray-700">
              Section
            </label>
            <select
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={selectedSection}
              onChange={(e) => setSelectedSection(e.target.value)}
            >
              <option value="">Select Section</option>
              {divisions.map((div) => (
                <option key={div.id} value={div.id}>
                  {div.division_name}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Search and All Present Button */}
        <div className="flex justify-between items-center mb-6">
          {/* Search Input Field */}
          <div className="w-3/5">
            <input
              type="text"
              className="block w-full py-2 px-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Search by student name..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          {/* Mark All Present Button */}
          <button
            className="py-2 px-6 bg-indigo-600 text-white font-semibold rounded-md shadow-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50"
            onClick={() =>
              setAttendanceData((prev) =>
                prev.map((student) => ({ ...student, status: "Present" }))
              )
            }
          >
            Mark All Present
          </button>
        </div>

        {/* Attendance Table */}
        <div className="overflow-x-auto shadow-md rounded-lg bg-white">
          <table className="min-w-full border-collapse">
            <thead>
              <tr className="bg-indigo-100 text-indigo-700">
                <th className="py-3 px-6 text-left font-semibold border">
                  Student ID
                </th>
                <th className="py-3 px-6 text-left font-semibold border">
                  Student Name
                </th>
                <th className="py-3 px-6 text-left font-semibold border">
                  Attendance Status
                </th>
                <th className="py-3 px-6 text-left font-semibold border">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan="4" className="text-center py-8 text-gray-500">
                    Loading...
                  </td>
                </tr>
              ) : filteredData.length > 0 ? (
                filteredData.map((row) => (
                  <tr key={row.id} className="hover:bg-gray-50">
                    <td className="py-4 px-6 border">{row.id}</td>
                    <td className="py-4 px-6 border">{row.studentName}</td>
                    <td className="py-4 px-6 border">{row.status}</td>
                    <td className="py-4 px-6 border">
                      <button
                        className={`py-1 px-4 rounded-md text-white font-semibold ${
                          row.status === "Present"
                            ? "bg-red-500 hover:bg-red-400"
                            : "bg-green-500 hover:bg-green-400"
                        }`}
                        onClick={() => handleStatusToggle(row.id)}
                      >
                        {row.status === "Present"
                          ? "Mark Absent"
                          : "Mark Present"}
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="text-center py-8 text-gray-500">
                    No students found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Attendance Summary */}
        <div className="mt-8 flex justify-between items-center">
          <div className="text-lg shadow-md p-32 font-semibold text-gray-700">
            Total Students:{" "}
            <span className="text-indigo-600">{attendanceData.length}</span>
          </div>
          <div className="text-lg shadow-md p-32 font-semibold text-gray-700">
            Present Students:{" "}
            <span className="text-indigo-600">
              {
                attendanceData.filter((student) => student.status === "Present")
                  .length
              }
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Attendance;
