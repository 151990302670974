import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import StudentTable from "./component/StudentTable";
import StudentDetails from "./component/StudentDetails";
import IdCard from "./Certificate/IdCard";
import TransferForm from "./Certificate/TransferForm";
import Bonafide from "./Certificate/Bonfite";
import Leaving from "./Certificate/Leaving";
import PaymentDetails from "./component/PaymentDetails";
import StudentProfile from "./component/StudentProfile";
import StudentForm from "./component/StudentForm";
import Login from "./component/Login";
import File from "./Certificate/Upload";
import FeeCategoryComponent from "./Certificate/Category";
import FeeSubCategoryComponent from "./Certificate/SubCategory";
import FeeDetails from "./Certificate/FeeDetails";
import BookForm from "./component/BookForm";
import IssueBookForm from "./component/IssueBookForm";
import BookList from "./component/BookList";
import StudentBookList from "./component/StudentBookList";
import ExpenditureManagement from "./component/ExpenditureManagement";
import Dashboard from "./dashboard/dashboard";
import Attendance from "./dashboard/Attendance";
import StudentAttendance from "./dashboard/StudentAttendance";
import TEacher from "./dashboard/TEacher";
import TeacherProf from "./dashboard/TeacherProf";
import StaffHome from "./dashboard/StaffHome";
import TAx from "./Pages/TAx";
import History from "./Pages/History";
import TeacherAdd from "./Pages/TeacherAdd";
import StudentF from "../src/component/StudentF";
import BookPage from "../src/Pages/BookPage";
import Header from "./dashboard/Header";
const CategoryForFee = () => {
  return (
    <div className="flex-col gap-14">
      <Header />
      <FeeCategoryComponent />
      <FeeSubCategoryComponent />
    </div>
  );
};
const Book = () => {
  return (
    <>
      <Header />
      <div className="container mx-auto">
        <BookForm onBookAdded={() => {}} />
        <BookList />
      </div>
    </>
  );
};
const Issue = () => {
  return (
    <>
      <Header />
      <div className="container mx-auto">
        <IssueBookForm onBookIssued={() => {}} />

        <StudentBookList />
      </div>
    </>
  );
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Dashboard />} />
        <Route path="/staffHome" element={<StaffHome />} />
        <Route path="/studentTable" element={<StudentTable />} />
        <Route path="/attendance" element={<Attendance />} />
        <Route path="/studentAttendance" element={<StudentAttendance />} />
        <Route path="/teacher" element={<TEacher />} />
        <Route path="/teacherProf" element={<TeacherProf />} />
        <Route path="/add-student" element={<StudentForm />} />
        <Route path="/student-details/:id" element={<StudentProfile />} />
        <Route path="/payment-details" element={<PaymentDetails />} />
        <Route path="/id-card/:id" element={<IdCard />} />
        <Route path="/transfer-certificate/:id" element={<TransferForm />} />
        <Route path="/bonafide-certificate/:id" element={<Bonafide />} />
        <Route path="/nirgam-certificate/:id" element={<Leaving />} />
        <Route path="/test" element={<File />} />
        <Route path="/category-for-fee" element={<CategoryForFee />} />
        <Route path="/fee-details/:id" element={<FeeDetails />} />
        <Route path="/his" element={<History />} />
        <Route path="/bookpage" element={<BookPage />} />
        <Route path="/bookdetails" element={<Book />} />
        <Route path="/issue-book" element={<Issue />} />
        <Route path="/expense" element={<ExpenditureManagement />} />
        <Route path="/tax" element={<TAx />} />
        <Route path="/studentF" element={<StudentF />} />
        <Route path="/yash" element={<Header />} />
        {/* <Route path="/tax" element={<TeacherAdd/>} /> */}
        <Route path="*" element={<h1>Page Not Found</h1>} />
      </Routes>
    </Router>
  );
};

export default App;
