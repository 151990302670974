// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import { FaBars, FaTimes } from 'react-icons/fa'; // Icons for the hamburger menu and close
// import { IoMdArrowDropdown } from 'react-icons/io'; // For dropdown arrow icon
// import logo from '../img/1000077337-removebg-preview.png';
// import profileImage from '../img/Rectangle 65.png'; // Replace with your actual profile image path

// const Header = () => {
//   const [isOpen, setIsOpen] = useState(false); // State to manage sidebar visibility
//   const [dropdownOpen, setDropdownOpen] = useState(false); // State to manage profile dropdown

//   // Navigation options without icons (icons removed only here)
//   const sections = [
//     { name: 'Add Student', path: '/add-student' },
//     { name: 'Fee Category', path: '/fee-category' },
//     { name: 'Update Fees', path: '/update' },
//     { name: 'Attendance', path: '/attendance-fees' },
//     { name: 'Certificate', path: '/studentTable' },
//     { name: 'Staff', path: '/staff' },
//     { name: 'Library', path: '/library' },
//     { name: 'Expense', path: '/expense' },
//   ];

//   return (
//     <div>
//       {/* Navbar */}
//       <nav className="flex items-center justify-between bg-[#1C61B8BA] text-black pt-1 px-2">
//         <div className="flex items-center">
//           {/* Logo */}
//           <img src={logo} alt="Logo" className="h-20 w-24 lg:pt-1 pr-8" />
//         </div>
//         {/* Hamburger Icon */}
//         <div className="md:hidden">
//           <button onClick={() => setIsOpen(!isOpen)} className="text-black">
//             {isOpen ? <FaTimes size={30} /> : <FaBars size={30} />}
//           </button>
//         </div>
//         {/* Navigation Links and Profile for larger screens */}
//         <div className="hidden md:flex space-x-4 items-center">
//           {sections.map((section) => (
//             <Link key={section.name} to={section.path} className="hover:bg-[#1C61B8BA] p-2 rounded transition-colors duration-300">
//               <span>{section.name}</span>
//             </Link>
//           ))}

//           {/* Profile with dropdown for larger screens */}
//           <div className="relative">
//             <button onClick={() => setDropdownOpen(!dropdownOpen)} className="flex items-center space-x-2 focus:outline-none">
//               <img src={profileImage} alt="Profile" className="h-10 w-10 rounded-full" />
//               <IoMdArrowDropdown className="text-black" />
//             </button>

//             {/* Dropdown Menu */}
//             {dropdownOpen && (
//               <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-2 z-50">
//                 <Link to="/profile" className="block px-4 py-2 text-black hover:bg-gray-200">
//                   Profile
//                 </Link>
//                 <button onClick={() => alert('Logout clicked')} className="block w-full text-left px-4 py-2 text-black hover:bg-gray-200">
//                   Logout
//                 </button>
//               </div>
//             )}
//           </div>
//         </div>
//       </nav>

//       {/* Sidebar for smaller screens */}
//       {isOpen && (
//         <div className="md:hidden bg-[#1C61B8BA] text-black space-y-2 p-4">
//           {sections.map((section) => (
//             <Link
//               key={section.name}
//               to={section.path}
//               onClick={() => setIsOpen(false)} // Close sidebar on link click
//               className="block hover:bg-[#7fb4e840] p-2 rounded transition-colors duration-300"
//             >
//               <span>{section.name}</span>
//             </Link>
//           ))}

//           {/* Profile with dropdown for smaller screens */}
//           <div className="relative">
//             <button onClick={() => setDropdownOpen(!dropdownOpen)} className="flex items-center space-x-2 focus:outline-none">
//               <img src={profileImage} alt="Profile" className="h-10 w-10 rounded-full" />
//               <IoMdArrowDropdown className="text-black" />
//             </button>

//             {/* Dropdown Menu */}
//             {dropdownOpen && (
//               <div className="absolute   right-[480px]  w-48 bg-white rounded-md shadow-lg py-2 z-50">
//                 <Link to="/profile" className="block px-4 py-2 text-black hover:bg-gray-200">
//                   Profile
//                 </Link>
//                 <button onClick={() => alert('Logout clicked')} className="block w-full text-left px-4 py-2 text-black hover:bg-gray-200">
//                   Logout
//                 </button>
//               </div>
//             )}
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Header;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  FaBars,
  FaTimes,
  FaUserGraduate,
  FaMoneyCheckAlt,
  FaEdit,
  FaCalendarCheck,
  FaCertificate,
  FaUsers,
  FaBook,
  FaFileInvoiceDollar,
} from "react-icons/fa"; // Import icons
import { IoMdArrowDropdown } from "react-icons/io"; // For dropdown arrow icon
import logo from "../img/1000077337-removebg-preview.png";
import profileImage from "../img/Rectangle 65.png"; // Replace with your actual profile image path

const Header = () => {
  const [isOpen, setIsOpen] = useState(false); // State to manage sidebar visibility
  const [dropdownOpen, setDropdownOpen] = useState(false); // State to manage profile dropdown

  // Navigation options with icons
  const sections = [
    { name: "Add Student", path: "/add-student", icon: <FaUserGraduate /> },
    {
      name: "Fee Category",
      path: "/category-for-fee",
      icon: <FaMoneyCheckAlt />,
    },
    { name: "Update Fees", path: "/studentF", icon: <FaEdit /> },
    { name: "Attendance", path: "/attendance", icon: <FaCalendarCheck /> },
    { name: "Certificate", path: "/studentTable", icon: <FaCertificate /> },
    { name: "Staff", path: "/staffHome", icon: <FaUsers /> },
    { name: "Library", path: "/bookpage", icon: <FaBook /> },
    { name: "Expense", path: "/expense", icon: <FaFileInvoiceDollar /> },
    {
      name: "PF & PTax Report",
      path: "/tax",
      icon: <FaCertificate />,
    },
  ];

  return (
    <div>
      {/* Navbar */}
      <nav className="flex items-center justify-between bg-[#1C61B8BA] text-black pt-1 px-2">
        <Link to="/">
          <div className="flex items-center">
            {/* Logo */}
            <img src={logo} alt="Logo" className="h-20 w-24 lg:pt-1 pr-8" />
          </div>
        </Link>
        {/* Hamburger Icon */}
        <div className="md:hidden">
          <button onClick={() => setIsOpen(!isOpen)} className="text-black">
            {isOpen ? <FaTimes size={30} /> : <FaBars size={30} />}
          </button>
        </div>
        {/* Navigation Links and Profile for larger screens */}
        <div className="hidden md:flex space-x-4 items-center">
          {sections.map((section) => (
            <Link
              key={section.name}
              to={section.path}
              className="hover:bg-[#1C61B8BA] p-2 rounded transition-colors duration-300 flex items-center space-x-2"
            >
              {section.icon} {/* Display the icon */}
              <span>{section.name}</span>
            </Link>
          ))}

          {/* Profile with dropdown for larger screens */}
        </div>
      </nav>

      {/* Sidebar for smaller screens */}
      {isOpen && (
        <div className="md:hidden bg-[#1C61B8BA] text-black space-y-2 p-4">
          {sections.map((section) => (
            <Link
              key={section.name}
              to={section.path}
              onClick={() => setIsOpen(false)} // Close sidebar on link click
              className="block hover:bg-[#7fb4e840] p-2 rounded transition-colors duration-300 flex items-center space-x-2"
            >
              {section.icon} {/* Display the icon */}
              <span>{section.name}</span>
            </Link>
          ))}

          {/* Profile with dropdown for smaller screens */}
          <div className="relative">
            <button
              onClick={() => setDropdownOpen(!dropdownOpen)}
              className="flex items-center space-x-2 focus:outline-none"
            >
              <img
                src={profileImage}
                alt="Profile"
                className="h-10 w-10 rounded-full"
              />
              <IoMdArrowDropdown className="text-black" />
            </button>

            {/* Dropdown Menu */}
            {dropdownOpen && (
              <div className="absolute right-[480px] w-48 bg-white rounded-md shadow-lg py-2 z-50">
                <Link
                  to="/profile"
                  className="block px-4 py-2 text-black hover:bg-gray-200"
                >
                  Profile
                </Link>
                <button
                  onClick={() => alert("Logout clicked")}
                  className="block w-full text-left px-4 py-2 text-black hover:bg-gray-200"
                >
                  Logout
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
